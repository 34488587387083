import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import { Container, Section, Title } from "bloomer"
import BlockContent from "../components/Sanity/blockContent"
import { useStaticQuery, graphql } from "gatsby"

const InclusiveCreations = ({}) => {
  const data = useStaticQuery(graphql`
    {
      sanityInclusiveCreation {
        _rawBody
        title
      }
    }
  `)
  return (
    <main>
      <Layout>
        <SEO title="Inclusive Creation" />
        <Section>
          <Container>
            <p aria-hidden="true" className="inclusiveCirclesWrapper">
              <span className="inclusiveCircles1">I</span>
              <span className="inclusiveCircles2">C</span>
            </p>
            <Title className="title is-1 introTitle" id="main">
              {data.sanityInclusiveCreation.title}
            </Title>
            <BlockContent blocks={data.sanityInclusiveCreation._rawBody} />
          </Container>
        </Section>
      </Layout>
    </main>
  )
}

export default InclusiveCreations
